h1,
h2 {
    text-align: center;
    text-decoration: underline;
}

h3 {
    text-align: center;
    padding-left: 150px;
    padding-right: 150px;
}