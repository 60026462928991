/* Add this to your Card.css file or another global style file */
.card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}


.card-container {
  width: 28rem;
  border-radius: 1rem;
  box-shadow: 0px 10px 8px #999;
  display: flex;
  flex-direction: column;
  margin: 5.5rem;
  background-color: white;
  height: fit-content;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.card-img {
  width: 40%;
  border-radius: 1rem 1rem 0 0;
}

.card-title {
  margin: 0.5rem 5%;
}

.card-description {
  margin: 0.5rem 5%;
}

.card-btn {
  text-align: center;
  width: 90%;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  background-color: #2563eb;
  color: white;
  text-decoration: none;
  margin: 0.5rem 0.5rem;
}